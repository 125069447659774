<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Set date</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4"  v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-else>
          <div class="row">
            <div class="col-6">
              <div class="form-group full-width">
                <label class="mb-1">Date</label>
                <div class="w-100">
                  <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    class="daterange-dashboard"
                    opens="center"
                    :singleDatePicker="true"
                    :auto-apply="true"
                    :timePicker="false"
                    :time-picker24-hour="false"
                    :ranges="false"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
                    <template v-slot:input="picker">
                      {{ picker.startDate | date }}
                      <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"  v-if="!ui.loading">
        <button type="submit"
                @click="save"
                class="btn bg-primary submit">
          <span v-if="ui.btnLoading"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="!ui.btnLoading">Save</span>
        </button>
        <button type="reset" class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';

export default {
  name: 'ExportFilterModal',
  components: { DateRangePicker },
  props: {
    date: {type: String},
  },
  data() {
    return {
      ui: {
        loading: false,
        btnLoading: false
      },
      dateRange: {
        startDate: this.date ? moment(this.date) : new Date(),
      },
    }
  },
  methods: {
    save() {
      this.ui.btnLoading = true;
      this.$emit('updateDate', moment(this.dateRange.endDate).format('YYYY-MM-DD'))
      this.ui.btnLoading = false;
    },
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MMM DD, YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    height: 30% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
  }
  .modal-body {
    padding: 15px;
    margin-left: 1rem;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.daterange-dashboard {
  &::v-deep .daterangepicker {
    min-width: 385px!important;
  }

  &::v-deep {
    .ranges {
      @media (max-width: 568px) {
        widht: 30%;
      }
    }
  }
}
</style>
